import React from "react";
import Redirect from "components/Redirect";

import { isAndroid, isIOS } from "react-device-detect";

const Rate: React.FC = () => {
    let redirectURL = "https://alternativeto.net/software/ente/about/";
    if (isAndroid) {
        redirectURL =
            "https://play.google.com/store/apps/details?id=io.ente.photos";
    } else if (isIOS) {
        redirectURL = "https://apps.apple.com/app/id1542026904";
    }
    return <Redirect to={redirectURL} />;
};

export default Rate;
